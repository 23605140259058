<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <el-select size="small" v-model="queryForm.term_id"   filterable placeholder="学期" style=""
                    @change="getList()">
                    <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                        :value="item.id"></el-option>
                </el-select>


        <el-button size="small" icon="el-icon-refresh" @click="refreshClasses" type="primary">从课程表同步班级</el-button>
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>基础设置</el-breadcrumb-item>
          <el-breadcrumb-item>班级管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="height: 85%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span>系部</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="addXibu">添加系部</el-button>
            </div>
            <div style="height: 70vh; overflow: auto;">
              <el-tag @click="ckdGrade = {}; getList();" :effect="!ckdGrade.id ? 'dark' : 'light'"
                style="display: block; margin-bottom: 10px; position: relative;">
                全部
              </el-tag>
              <el-tag @click="showXibuCls(tag)" :effect="ckdGrade.id == tag.id ? 'dark' : 'light'"
                style="display: block; margin-bottom: 10px; position: relative;" v-for="tag in gradeList" :key="tag.name"
                closable @close="removeGrade(tag)">
                {{ tag.name }} <i class="el-icon-edit" @click.stop="editGrade(tag)"
                  style="position: absolute; right: 30px; top: 8px;;"></i>
              </el-tag>
            </div>
          </el-card>
        </el-col>
        <el-col :span="18">
          <el-table height="calc(100vh - 150px)" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id"
            border stripe>
            <el-table-column type="index" label="序号" width="60px"></el-table-column>
            <el-table-column label="学期" prop="term_title"></el-table-column>
            <el-table-column label="系部名称" prop="grade_name"></el-table-column>
            <el-table-column label="班级名称" prop="name"></el-table-column>
            <el-table-column label="班级人数" prop="people_count"></el-table-column>
            <el-table-column label="班主任" prop="bzrname"></el-table-column>


            <!-- <el-table-column prop="sort" width="80px" label="排序"></el-table-column> -->

            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">

                <el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>

                <el-button type="text" @click="viewInfo(scope.row)" size="small">课程</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <el-dialog ref="ruleForm" title="系部编辑" :visible.sync="dialogXibuForm" :rules="rules">
      <el-form :model="formData" label-width="120px">
        <el-form-item label="系部名称">
          <el-input v-model.trim="formData.name" style="width: 50%" placeholder="请输入系部名称"></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="title">
          <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)" controls-position="right" />
        </el-form-item>


      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right">
          <el-button size="small" type="default" @click="dialogXibuForm = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitFormGrade('ruleForm')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog ref="ruleForm" title="班级详情" :visible.sync="dialogClsForm" :rules="rules">
      <el-form :model="formData" label-width="120px">
        <el-form-item label="班级名称">
          <el-input v-model.trim="clsInfo.name" style="width: 50%" placeholder="请输入班级名称"></el-input>
        </el-form-item>


        <el-form-item label="系部">
          <el-select filterable v-model="clsInfo.grade_id">
            <el-option v-for="(item, i) in gradeList" :key="i" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>



        <el-form-item label="班级人数" prop="title">
          <el-input-number :min="0" v-model.trim="clsInfo.people_count" placeholder="请输入人数(数字)"
            controls-position="right" />
        </el-form-item>

        <el-form-item label="班主任">
          <el-select filterable v-model="clsInfo.bzrid">
            <el-option v-for="(item, i) in teacherList" :key="i" :label="item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right">
          <el-button size="small" type="default" @click="dialogClsForm = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>



    <el-dialog ref="ruleForm" title="课程详情" :visible.sync="dialogClsInfo" width="80%">
      <el-table :data="DataList" height="100%" stripe border size="small" style="width:fit-content;width:100%;">
            <el-table-column type="index" label="编号" width="55" align="center"></el-table-column>


            <el-table-column prop="course_name" label="课程名称" min-width="185" align="center"></el-table-column>
            <!-- <el-table-column prop="course_desc" label="课程描述" min-width="300" align="center"></el-table-column> -->
            <el-table-column prop="class_name" label="授课班级" width="120" align="center"></el-table-column>
            <el-table-column prop="teacher_name" label="授课老师" width="120" align="center"></el-table-column>
            <el-table-column prop="weekday" label="授课周" width="100" align="center">
                <template slot-scope="scope">
                    <span style="" @click="view(scope.row)">周{{ scope.row.weekday
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="jie" label="授课课时" width="100" align="center"></el-table-column>
            <el-table-column prop="room_name" label="授课教室" min-width="200" align="center">
                <template slot-scope="scope">
                    <span style="" @click="view(scope.row)">{{ scope.row.room_name
                        }}({{ scope.row.address }})</span>
                </template>
            </el-table-column>

            

        </el-table>
    </el-dialog>

    <div style="height: 20px"></div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      gradeList: [],
      dialogForm: false,
      dialogXibuForm: false,
      dialogClsForm: false,
      dialogClsInfo: false,
      modalTitle: "添加",
      queryForm: { term_id: 0 },
      formData: {
        id: 0,
        name: "",
        sort: 99,

      },
      clsInfo: {

      },

      page: {
        count: 0,
        current_page: 1,
        per_page: 11,
        total_page: 0,
      },
      overlap: null,
      ckdGrade: {},
      rules: {
        title: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
      },
      teacherList: [],
      termList: [],
      termIdInit: 0,
    };
  },
  mounted() {
    this.getTerm()

  },
  methods: {
    getTerm() {
      return new Promise(resolve => {
        this.$http.post(`/api/t_sch_terms_list`).then(res => {
          if (res && res.data.data && res.data.data.length > 0) {
            this.termList = res.data.data
            res.data.data && res.data.data.map(a => {

              if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {

                this.queryForm.term_id = a.id

              }
            })

          }
          this.$forceUpdate()
          resolve()

          this.getList();
          this.getGrades()
          this.getTeachers()
        })
      })
    },
    addXibu(e) {
      this.formData = {
        id: 0,
        name: "",
        sort: 99,
      }
      this.dialogXibuForm = true

    },
    removeGrade(item) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/t_sch_grade_delete", { id: item.id }).then((res) => {
          this.getGrades()

        });
      }).catch(() => {

      });



    },
    editGrade(e) {
      this.formData = { ...e }
      this.dialogXibuForm = true
    },

    refreshClasses() {
      this.$http.post("/api/tongbu_class_from_syscourse_table").then((res) => {

        this.$message.success("同步成功！")

      });

    },
    getGrades() {
      this.$http.post("/api/t_sch_grade_all").then((res) => {
        this.gradeList = res.data;
      });
    },
    showXibuCls(e) {
      this.ckdGrade = e
      this.getList()
    },
    getList() {
      this.$http.post("/api/t_sch_class_list", { pagesize: 2000, grade_id: this.ckdGrade.id,term_id:this.queryForm.term_id }).then((res) => {
        this.tableData = res.data.data;
      });
    },
    getTeachers() {
      this.$http.post("/api/teacher_dropdown", {}).then((res) => {
        this.teacherList = res.data;
      });
    },

    handleModify(row) {
      this.clsInfo = JSON.parse(JSON.stringify(row))
      this.dialogClsForm = true
    },
    submitForm(formName) {
      this.$http
        .post("/api/t_sch_class_edit2", this.clsInfo)
        .then((res) => {
          this.$message.success("保存成功");
          this.dialogClsForm = false;
          this.getList();
        });

    },
    submitFormGrade(formName) {
      this.$http
        .post("/api/t_sch_grade_edit", this.formData)
        .then((res) => {
          this.$message.success("保存成功");
          this.dialogXibuForm = false;
          this.getGrades();
        });

    },
    viewInfo(row) {
      this.$http
        .post("/api/t_sch_syskc_list", {pagesize:2000,term_id:this.queryForm.term_id,class_name:row.name})
        .then((res) => {
          this.DataList = res.data
          this.dialogClsInfo = true;
        });

    },
    

  },

};
</script>

<style lang="less">.el-tag__close {
  float: right;
  margin-top: 8px;
}</style>